
import Vue from "vue";
import { mapState } from "vuex"
import { formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";

export default Vue.extend({
    name: "CreateReportModalOption",
    props: {
    },
    data() {
        return {
            isExistingReport: false,
            DateTime
        };
    },
    computed: {
        ...mapState("reports", ["reports", "existingReports"]),
        hasAReport(): boolean {
            return this.reports.length > 0;
        },
    },
    methods: {
        formatDate,
        clearReport() {
            this.$store.commit('reports/SET_EXISTING_REPORT', '')
        }
    },
});
