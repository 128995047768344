var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": 'create-report-option',
      "title": 'Create Report',
      "description": `Complete the steps to start reporting`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    },
    on: {
      "hide": _vm.clearReport
    }
  }, [!_vm.isExistingReport ? _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('h3', {
    staticClass: "badge__header"
  }, [_vm._v("Select where to start from")]), _c('p', {
    staticClass: "badge__description"
  }, [_vm._v("You can either start from scratch, or you can use an old report as a template.")])]), _c('b-col', {
    attrs: {
      "cols": "6"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('toggleCreateReportModal');
      }
    }
  }, [_c('div', {
    staticClass: "report__wrapper"
  }, [_c('h3', {
    staticClass: "badge__header"
  }, [_vm._v("Create new...")]), _c('p', {
    staticClass: "badge__description"
  }, [_vm._v("Create a brand new report.")])])]), _vm.hasAReport ? _c('b-col', {
    attrs: {
      "cols": "6"
    },
    on: {
      "click": function ($event) {
        _vm.isExistingReport = true;
      }
    }
  }, [_c('div', {
    staticClass: "report__wrapper"
  }, [_c('h3', {
    staticClass: "badge__header"
  }, [_vm._v("Create from...")]), _c('p', {
    staticClass: "badge__description"
  }, [_vm._v("Create from a previously "), _c('br'), _vm._v(" submitted report.")])])]) : _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('div', {
    staticClass: "report--inactive"
  }, [_c('h3', {
    staticClass: "badge__header text-black-60"
  }, [_vm._v("Create from...")]), _c('p', {
    staticClass: "badge__description text-black-60"
  }, [_vm._v("Create from a previously "), _c('br'), _vm._v(" submitted report.")])])])], 1) : _vm._e(), _vm.isExistingReport ? _c('b-row', [_c('b-col', {
    staticClass: "d-flex",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    on: {
      "click": function ($event) {
        _vm.isExistingReport = false;
      }
    }
  }, [_c('ba-base-icon', {
    staticClass: "mr-3 pointer",
    attrs: {
      "name": "ba-arrow-back"
    }
  })], 1), _c('div', [_c('h3', {
    staticClass: "badge__header"
  }, [_vm._v("Use as template")]), _c('p', {
    staticClass: "badge__description"
  }, [_vm._v("Select a previously approved report to create from.")])])]), _vm.hasAReport ? _c('div', {
    staticClass: "row px-3 pb-5"
  }, [_c('h3', {
    staticClass: "timeline--text col-lg-12 mt-3"
  }, [_vm._v("Available reports")]), _vm._l(_vm.reports, function (report) {
    return _c('div', {
      key: report.id,
      staticClass: "col-lg-6 mt-3 pointer"
    }, [_c('div', {
      staticClass: "report__existing",
      on: {
        "click": function ($event) {
          _vm.isExistingReport = false;
          _vm.$emit('getExistingReport', report);
        }
      }
    }, [_c('h3', {
      staticClass: "timeline--text"
    }, [_vm._v(_vm._s(!report.reportingTimelineFrom || !report.reportingTimelineTo ? "Not provided" : `${_vm.formatDate(report.reportingTimelineFrom)} - ${_vm.formatDate(report.reportingTimelineTo)}`))]), _c('div', {
      staticClass: "d-flex align-items-start mt-3"
    }, [_c('div', {
      staticClass: "default-profile-image-ext mr-2"
    }, [_c('p', {
      staticClass: "mb-0 text-white"
    }, [_vm._v(_vm._s(report.createdByUser.firstName[0].toUpperCase()) + _vm._s(report.createdByUser.lastName[0].toUpperCase()))])]), _c('p', {
      staticClass: "text--subtext"
    }, [_vm._v(_vm._s(report.createdByUser ? `${report.createdByUser.firstName} ${report.createdByUser.lastName}` : "Not sent"))])]), _c('p', {
      staticClass: "badge__description mt-3"
    }, [_vm._v(_vm._s(_vm.formatDate(report.dateCreated, _vm.DateTime.DATETIME_SHORT)))])])]);
  })], 2) : _vm._e()], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }