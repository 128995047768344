var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "container-fluid py-6 mt-body bg-white min-vh-100"
  }, [_c('BaBreadcrumb', {
    attrs: {
      "back-text": "Back home"
    }
  }), _c('div', {
    staticClass: "mb-5 d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _vm.canCreateReport ? _c('b-button', {
    staticClass: "text-black px-4 py-2 border-0 rounded-custom",
    attrs: {
      "variant": "warning",
      "id": "BA_AMP_CREATE_REPORT"
    },
    on: {
      "click": _vm.takeAction
    }
  }, [_vm._v(_vm._s(_vm.buttonText))]) : _vm._e()], 1), _c('b-row', {
    staticClass: "mx-0 mt-5",
    attrs: {
      "no-gutters": ""
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-nav', {
    staticClass: "ba-nav flex-nowrap"
  }, [_vm._l(_vm.filteredTabs, function (tab, ix) {
    return _c('b-nav-item', {
      key: ix,
      staticClass: "nav-item",
      attrs: {
        "to": {
          query: {
            tab: tab.value
          }
        },
        "active": _vm.isActiveTab(tab.value),
        "replace": "",
        "active-class": "text-yellow-150"
      }
    }, [_c('div', {
      staticClass: "mt-2 pl-3"
    }, [_c('div', {
      staticClass: "navmenu--text",
      class: {
        'text-black-40': !_vm.isActiveTab(tab.value),
        'text-yellow-150': _vm.isActiveTab(tab.value)
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]), _vm.isActiveTab(tab.value) ? _c('div', {
      staticClass: "nav-item__border"
    }) : _vm._e()])]);
  }), _c('div', {
    staticClass: "w-100 nav-item__divider"
  })], 2)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_vm.currentTab ? _c(_vm.currentTab.component, _vm._b({
    tag: "component"
  }, 'component', {
    isLoading: _vm.isLoading
  }, false)) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('nccg-instruction-modal', {
    attrs: {
      "isCreating": _vm.isCreating
    },
    on: {
      "confirmed": function ($event) {
        return _vm.createReport($event);
      },
      "toggleModal": _vm.toggleOptionModals
    }
  }), _c('create-report-modal-option', {
    on: {
      "toggleCreateReportModal": _vm.showNccgModal,
      "getExistingReport": function ($event) {
        return _vm.fetchSelectedReport($event);
      }
    }
  })], 1)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "company__wrapper"
  }, [_c('h3', {
    staticClass: "comapny__text--header"
  }, [_vm._v("Reports")]), _c('p', {
    staticClass: "comapny__text--description"
  }, [_vm._v("Find information regarding all the reports here")])]);

}]

export { render, staticRenderFns }