var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('div', {
    class: `report-item mb-3 ${_vm.textClass}`
  }, [_c('div', {
    staticClass: "report-item__header p-4",
    on: {
      "click": function ($event) {
        return _vm.goTo({
          name: _vm.HOME.VIEW_REPORT.NAME,
          params: {
            id: _vm.report.id
          }
        });
      }
    }
  }, [_c('div', [_c('h3', {
    staticClass: "report-item__header--header"
  }, [_vm._v("Report ID")]), _c('p', {
    staticClass: "report-item__header--subtext"
  }, [_vm._v(_vm._s(_vm.report.reportCode))])]), _c('div', [_c('h3', {
    staticClass: "report-item__header--header"
  }, [_vm._v("Report Timeline")]), _c('p', {
    staticClass: "report-item__header--subtext"
  }, [_vm._v(" " + _vm._s(!_vm.report.reportingTimelineFrom || !_vm.report.reportingTimelineTo ? "Not provided" : `${_vm.formatDate(_vm.report.reportingTimelineFrom)} - ${_vm.formatDate(_vm.report.reportingTimelineTo)}`) + " ")])]), _vm.report.currentDeskUser ? _c('div', [_c('h3', {
    staticClass: "report-item__header--header"
  }, [_vm._v("Current desk")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "default-profile-image mr-1"
  }, [_c('p', {
    staticClass: "mb-0 text-white"
  }, [_vm._v(_vm._s(_vm.report.currentDeskUser.firstName[0].toUpperCase()) + _vm._s(_vm.report.currentDeskUser.lastName[0].toUpperCase()))])]), _c('p', {
    staticClass: "report-item__header--subtext"
  }, [_vm._v(_vm._s(_vm.report.currentDeskUser ? `${_vm.report.currentDeskUser.firstName} ${_vm.report.currentDeskUser.lastName}` : "Not sent"))])])]) : _vm._e(), _vm.report.dateSubmittedInternally ? _c('div', [_c('h3', {
    staticClass: "report-item__header--header"
  }, [_vm._v("Date Approved Internally")]), _c('p', {
    staticClass: "report-item__header--subtext"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.report.dateSubmittedInternally, _vm.DateTime.DATETIME_SHORT)))])]) : _vm._e(), _c('div', {
    staticClass: "text-right"
  }, [_c('b-badge', {
    staticClass: "px-2 py-1",
    class: `text-${_vm.badgeColor.text}`,
    attrs: {
      "pill": "",
      "variant": _vm.badgeColor.bg
    }
  }, [_vm._v(_vm._s(_vm.reportStatus))]), _c('p', {
    staticClass: "report-item__header--time"
  }, [_vm._v("Last edited " + _vm._s(_vm.report.dateModified ? _vm.timeAgo(new Date(_vm.report.dateModified)) : "Not Modified"))])], 1)]), _c('div', {
    class: `report-item__bottom d-flex justify-content-between align-items-center ${_vm.bottomClass}`
  }, [_c('div', {
    staticClass: "report-item__bottom--items"
  }, [_c('p', {
    staticClass: "report-item__header--header mb-0"
  }, [_vm._v("Created by")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "default-profile-image mt-0 mr-1"
  }, [_c('p', {
    staticClass: "mb-0 text-white"
  }, [_vm._v(_vm._s(_vm.report.createdByUser.firstName[0].toUpperCase()) + _vm._s(_vm.report.createdByUser.lastName[0].toUpperCase()))])]), _c('p', {
    staticClass: "report-item__header--subtext pt-0"
  }, [_vm._v(_vm._s(_vm.report.createdByUser.firstName) + " " + _vm._s(_vm.report.createdByUser.lastName))])]), _c('p', {
    staticClass: "report-item__header--header mb-0"
  }, [_vm._v("on")]), _c('p', {
    staticClass: "report-item__header--subtext pt-0"
  }, [_vm._v(_vm._s(_vm.formatDate(_vm.report.dateCreated, _vm.DateTime.DATETIME_SHORT)))]), _vm.report.reportStatus === _vm.IReportStatus.AWAITING_SIGNING && _vm.report.reviewedBy ? _c('div', {
    staticClass: "d-flex"
  }, [_c('p', {
    staticClass: "report-item__header--header mb-0 mx-2"
  }, [_vm._v("Reviewed by")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "default-profile-image mt-0 mr-1"
  }, [_c('p', {
    staticClass: "mb-0 text-white"
  }, [_vm._v(_vm._s(_vm.report.reviewedBy[0].toUpperCase()) + _vm._s(_vm.report.reviewedBy[1].toUpperCase()))])]), _c('p', {
    staticClass: "report-item__header--subtext pt-0"
  }, [_vm._v(_vm._s(_vm.report.reviewedBy))])])]) : _vm._e()]), _vm.showActions ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-end",
    staticStyle: {
      "z-index": "9999",
      "position": "relative"
    }
  }, [_c('div', [_c('b-dropdown', {
    attrs: {
      "variant": "transparent",
      "no-caret": "",
      "menu-class": " my-0 py-0"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.showOptions ? _c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        }) : _vm._e()];
      },
      proxy: true
    }], null, false, 2670626181)
  }, _vm._l(_vm.itemOptions, function (itemOption, ix) {
    return _c('b-dropdown-item', {
      key: ix,
      staticClass: "my-0",
      on: {
        "click": function ($event) {
          return itemOption.action(_vm.report);
        }
      }
    }, [_c('div', {
      staticClass: "dropdown__wrapper"
    }, [_c('p', {
      staticClass: "dropdown__wrapper--text mb-0"
    }, [_vm._v(_vm._s(itemOption.text))])])]);
  }), 1)], 1)]) : _vm._e(), _vm.message ? _c('ba-button', {
    staticClass: "text-sm-2 text-gray-dark border border-gray-40 py-2 bg-transparent text-center",
    attrs: {
      "icon": "ba-info-red",
      "text": "Message"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('showMessage');
      }
    }
  }) : _vm._e()], 1)]), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('reassign-reviewer-modal', {
    attrs: {
      "isCreating": _vm.isCreating,
      "reportId": _vm.report.id,
      "report": _vm.report,
      "current-reviewer": _vm.report.currentDeskUser,
      "usage": "frc"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('reassign-signatories-modal', {
    attrs: {
      "report": _vm.reportID,
      "isLoading": _vm.isFetchingPreview
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }