import { render, staticRenderFns } from "./ReportsTab.vue?vue&type=template&id=235bbc58&scoped=true&"
import script from "./ReportsTab.vue?vue&type=script&lang=ts&"
export * from "./ReportsTab.vue?vue&type=script&lang=ts&"
import style0 from "./ReportsTab.vue?vue&type=style&index=0&id=235bbc58&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "235bbc58",
  null
  
)

export default component.exports