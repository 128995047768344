
import Vue from "vue";
import { required } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";

export default Vue.extend({
    name: "NccgInstructionModal",
    props: {
        isCreating: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState("reports", ["reports", "existingReports"]),
    },
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const minDate = new Date(today);
        return {
            DateTime,
            isConfirmed: false,
            minDate,
            form: {
                reportingFrom: "",
                reportingTo: "",
            },
        };
    },
    validations: {
        form: {
            reportingFrom: {
                required,
            },
            reportingTo: {
                required,
                minValue(val, { reportingFrom }) {
                    return new Date(val) > new Date(reportingFrom);
                },
            },
        },
    },
    methods: {
        formatDate,
        setState() {
            this.isConfirmed = false;
            this.$store.commit("reports/SET_EXISTING_REPORT", "");
        },
        clearReport() {
            this.$store.commit("reports/SET_EXISTING_REPORT", "");
        },
    },
});
