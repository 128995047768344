var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "table-tab h-100"
  }, [_c('div', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', {
    staticClass: "nowrap mb-3",
    attrs: {
      "align-v": "center",
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('label', {
    staticClass: "label--text text-gray-900 pt-3",
    attrs: {
      "for": "Search"
    }
  }, [_vm._v("Search")]), _c('b-input-group', {
    staticClass: "align-items-center border-black-10 border rounded-4 px-2 text-sm-2 bg-white"
  }, [_c('b-input-group-prepend', [_c('span', {
    staticClass: "bg-transparent border-0 border-none"
  }, [_c('b-icon', {
    attrs: {
      "icon": "search"
    }
  })], 1)]), _c('b-form-input', {
    staticClass: "bg-transparent text-sm-2 input__feild",
    attrs: {
      "placeholder": "Search by Creator, Report Timeline or ID"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('ba-dropdown', {
    attrs: {
      "form-class": "bg-white",
      "label": "Status Filter",
      "item-value": "key",
      "item-text": "label",
      "show-tooltip": "",
      "options": _vm.reportFilters
    },
    on: {
      "input": _vm.handleFilterChange
    },
    model: {
      value: _vm.reportFilter,
      callback: function ($$v) {
        _vm.reportFilter = $$v;
      },
      expression: "reportFilter"
    }
  })], 1)], 1)], 1)], 1), !_vm.isLoading && _vm.mappedReports.length == 0 || _vm.filteredReports.length == 0 && !_vm.isLoading ? _c('ba-base-tab', {
    staticClass: "bg-gray-20 h-100 py-5",
    attrs: {
      "icon": "human-illustration-blur",
      "isArrowIcon": true,
      "emptyText": `${_vm.filteredReports.length == 0 && _vm.search.length === 0 ? 'No report on your desk yet.<br/>' : 'No results for your search'}`
    }
  }) : _vm._e(), !_vm.isLoading ? _c('b-row', _vm._l(_vm.filteredReports, function (report, ix) {
    return _c('b-col', {
      key: ix,
      attrs: {
        "cols": "12"
      }
    }, [_c('report-item', {
      attrs: {
        "itemOptions": _vm.itemOptions,
        "report": report
      },
      on: {
        "deleted": function ($event) {
          return _vm.handleRemoveReport(report.id);
        }
      }
    })], 1);
  }), 1) : _c('b-row', _vm._l(6, function (i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "cols": "12"
      }
    }, [_c('b-card', {
      staticClass: "mb-4"
    }, [_c('b-skeleton', {
      attrs: {
        "width": "85%"
      }
    }), _c('b-skeleton', {
      attrs: {
        "width": "55%"
      }
    }), _c('b-skeleton', {
      attrs: {
        "width": "70%"
      }
    })], 1)], 1);
  }), 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }