
import Vue from "vue";
import { timeAgo, formatDate } from "@/utils/helpers";
import { DateTime } from "luxon";
import { mapGetters } from "vuex";
import { HOME } from "../../routes/endpoints";
import ReportService from "../../../api/report-service";
import ReassignReviewerModal from "../modals/ReassignReviewerModal.vue";
import ReassignSignatoriesModal from "../modals/ReassignSignatoriesModal.vue";

enum IReportStatus {
    DRAFT = 0,
    SIGNED = 12,
    FINALISED = 6,
    PROCESSING = 7,
    AWAITING_SIGNING = 3,
    REGULATOR_APPROVAL_IN_PROGRESS = 5,
}

export default Vue.extend({
    components: {
        ReassignReviewerModal,
        ReassignSignatoriesModal,
    },
    props: {
        "report": {
            type: Object,
            required: true,
        },
        "show-actions": {
            type: Boolean,
            default: true,
        },
        "textClass": {
            type: String,
            required: false,
            default: "border border-gray-light pointer",
        },
        "bottomClass": {
            type: String,
            required: false,
            default: "bg-gray-10",
        },
        "message": {
            type: Boolean,
            default: false,
            required: false,
        },
    },
    data() {
        return {
            HOME,
            IReportStatus,
            DateTime,
            isDeleting: false,
            isCreating: false,
            reportID: {},
            isFetchingPreview: false,
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
        isAdmin(): boolean {
            return this.profileData?.roles?.includes("Admin");
        },
        isCreator(): boolean {
            return this.profileData?.roles?.includes("ReportCreator");
        },
        showOptions(): boolean {
            return (
                (this.report.reportStatus === IReportStatus.DRAFT && (this.isAdmin || this.isCreator)) ||
                (this.report.reportStatus === IReportStatus.AWAITING_SIGNING && this.isAdmin) ||
                (this.report.reportStatus === IReportStatus.PROCESSING && this.isAdmin)
            );
        },
        itemOptions(): any {
            const val = [
                // {
                //     text: "View",
                //     action: (item: any) => {
                //         this.goTo({
                //             name: HOME.VIEW_REPORT.NAME,
                //             params: { id: item.id },
                //         });
                //     },
                // },
                // {
                //     text: "Delete",
                //     action() {
                //         //
                //     },
                // },
            ];

            if (this.report.reportStatus === IReportStatus.DRAFT) {
                val.push({
                    text: "Continue Editing",
                    action: (item: any) => {
                        this.goTo({
                            name: HOME.CREATE_REPORT.NAME,
                            query: { id: item.id },
                        });
                        this.clearReport();
                    },
                });
            }

            if (this.report.reportStatus === IReportStatus.DRAFT && this.report.regulatorPushCount === 0) {
                val.push({
                    text: "Delete",
                    action: (item: any) => {
                        this.deleteReport(item.id);
                    },
                });
            }

            if (this.report.reportStatus === IReportStatus.FINALISED) {
                val.push({
                    text: "Archive",
                    action: (item: any) => {
                        this.deleteReport(item.id);
                    },
                });
            }

            if (this.report.reportStatus === IReportStatus.PROCESSING && this.isAdmin) {
                val.push(
                    {
                        text: "Reassign Reviewer",
                        action: (item: any) => {
                            this.$bvModal.show(`modal-reassign-reviewer-${item.id}`);
                        },
                    },
                    {
                        text: "Re-notify Reviewer",
                        action: (item: any) => {
                            this.renotifyReviewer(item.id);
                        },
                    },
                );
            }

            if (this.report.reportStatus === IReportStatus.AWAITING_SIGNING && this.isAdmin) {
                val.push({
                    text: "Reassign Signatory",
                    action: (item: any) => {
                        this.openReassignSignatoryModal(item.id);
                    },
                });
            }

            return val;
        },
        dateLabel(): string {
            let res = "Date";
            if (this.report.reportStatus === IReportStatus.DRAFT) res = "Date Created";
            else if (this.report.reportStatus === IReportStatus.SIGNED) res = "Date Submitted Internally";
            else if (this.report.reportStatus === IReportStatus.FINALISED) res = "Date Submitted To Regulator";
            return res;
        },
        dateValue(): any {
            let res = "Date";
            if (this.report.reportStatus === IReportStatus.DRAFT) res = formatDate(this.report.dateCreated, DateTime.DATE_SHORT);
            else if (this.report.reportStatus === IReportStatus.SIGNED) res = formatDate(this.report.dateSubmittedInternally, DateTime.DATE_SHORT);
            else if (this.report.reportStatus === IReportStatus.FINALISED) res = formatDate(this.report.dateSentToRegulator, DateTime.DATE_SHORT);
            return formatDate(this.report.dateCreated, DateTime.DATETIME_SHORT);
        },
        reportStatus(): string {
            let res = "Draft";
            if (this.report.reportStatus === IReportStatus.DRAFT && !this.report.dateSubmittedInternally && this.report.regulatorPushCount === 0 && this.report.regulatorApprovalStatus === 0)
                res = "Draft";
            if (this.report.reportStatus === IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS) res = "Submitted to the FRC";
            else if (this.report.reportStatus === IReportStatus.DRAFT && this.report.dateSubmittedInternally) res = "Draft - Declined";
            else if (this.report.reportStatus === IReportStatus.SIGNED) res = "Signed";
            else if (this.report.reportStatus === IReportStatus.FINALISED) res = "Finalised";
            else if (this.report.reportStatus === IReportStatus.PROCESSING) res = "Awaiting Review";
            else if (this.report.reportStatus === IReportStatus.AWAITING_SIGNING) res = "Awaiting Signing";
            return res;
        },
        badgeColor() {
            let res = { bg: "blue-100", text: "primary" };
            if (this.report.reportStatus === IReportStatus.DRAFT && this.report.regulatorPushCount === 0) res = { bg: "blue-100", text: "primary" };
            else if ((this.report.reportStatus === IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS || this.report.reportStatus === IReportStatus.SIGNED) && this.report.regulatorApprovalStatus !== 3)
                res = { bg: "success-5", text: "success-90" };
            else if ((this.report.reportStatus === IReportStatus.REGULATOR_APPROVAL_IN_PROGRESS || this.report.reportStatus === IReportStatus.SIGNED) && this.report.regulatorApprovalStatus === 3)
                res = { bg: "red-10", text: "danger" };
            else if (this.report.reportStatus === IReportStatus.PROCESSING) res = { bg: "yellow-50", text: "yellow-100" };
            else if (this.report.reportStatus === IReportStatus.AWAITING_SIGNING) res = { bg: "yellow-130", text: "yellow-10" };
            return res;
        },
    },
    methods: {
        timeAgo,
        formatDate,
        goTo({ name, params, query }: { name: string; params?: any; query?: any }) {
            this.$router.push({ name, params, query });
            this.$store.commit("reports/SET_DETAILS_STATE", false);
        },
        clearReport() {
            this.$store.commit("reports/SET_EXISTING_REPORT", "");
        },
        async deleteReport(id: string) {
            this.isDeleting = true;
            try {
                const res = await ReportService.deleteReport(id);
                // console.log(res);
                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
                this.$emit("deleted");
            } catch (error) {
                //
            } finally {
                this.isDeleting = false;
            }
        },
        async renotifyReviewer(id: string) {
            this.isDeleting = true;
            try {
                const res = await ReportService.renotifyReviewer(id);
                // console.log(res);
                this.$bvToast.toast(res.data.message || "Reviewer re-notified successfully", {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
            } catch (error) {
                //
            } finally {
                this.isDeleting = false;
            }
        },
        async fetchReportDetails(id: any) {
            this.isFetchingPreview = true;
            try {
                const res = await ReportService.fetchReportDetails(id as string);
                this.reportID = res.data;
            } catch (error) {
                this.isFetchingPreview = false;
            } finally {
                this.isFetchingPreview = false;
            }
        },
        openReassignSignatoryModal(id: any) {
            this.fetchReportDetails(id);
            this.$bvModal.show(`modal-reassign-signatories-${id}`);
        },
    },
});
