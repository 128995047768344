
import Vue from "vue";
import { required, maxLength, minLength } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import CreateUserModal from "@/app/components/modals/CreateUserModal.vue";
import ReportService from "@/api/report-service";
import { HOME } from "@/app/routes/endpoints";
import UserService from "@/api/user-service";

export default Vue.extend({
    components: {
        CreateUserModal,
    },
    props: {
        report: Object,
        isLoading: {
            type: Boolean,
            default: false,
        },
        isReassigningSignatory: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            isSaving: false,
            isFetching: false,
            isSavingDraft: false,
            isFetchingPreview: false,
            isReassigning: false,
            loading: false,
            reportDetails: {},
            form: {
                managingDirectorSignatories: [],
                chairmanOfBoardSignatories: [],
                executives: [],
                investorRelationsOfficers: [],
                companySecretaries: [],
                complianceOfficers: [],
                companyContactPersons: [],
                reviewers: [],
                reviewersIds: []
            },
            allreviewers: [],
            signatoriesPositions: [
                {
                    position:
                        "Board Chairman or Chairman, Committee responsible for Governance",
                    normalizedPosition: "SN3",
                    description: "This is the head of the department",
                    isRequired: true,
                    selected: [],
                },
                {
                    position: "Company Secretary or Compliance officer",
                    normalizedPosition: "SN4",
                    description: "",
                    isRequired: true,
                    selected: [],
                },
            ],
            reportPreview: {
                reportInformation: {
                    reportStatus: null,
                    reportCode: "",
                    currentDeskUser: {
                        email: "",
                        firstName: "",
                        id: "",
                        lastName: "",
                        profileImgId: "",
                        userName: "",
                    },
                    signatoryIds: [],
                },
                companyDetails: {},
                licenses: [],
                policies: [],
                registrarCompanies: [],
                boardConsultants: [],
                governanceConsultants: [],
                auditors: [],
                charters: [],
                structure: {
                    commitees: [],
                    structurePositions: [],
                },
                principles: [],
                signatures: [],
            },
        };
    },
    computed: {
        ...mapState("users", ["users"]),
        ...mapState("users", {
            signatories: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportSignatory",
                )?.users,
            reviewers: (state: any) =>
                state.levels?.find(
                    (level: any) => level.category === "ReportReviewer",
                )?.users,
        }),
    },
    methods: {
        ...mapActions({
            fetchUsers: "users/fetchUsers",
        }),
        handleUserEditValue({
            modalId,
            data,
            ref,
        }: {
            modalId: string;
            data: any;
            ref: string;
        }) {
            //
            (this.$refs[ref] as any).fetchUser(data.id).then(() => {
                this.$bvModal.show(`${modalId}`);
            });
        },
        async reassignSignatories() {
            this.isReassigning = true;

            const body = this.signatoriesPositions.map((position) => {
                return {
                    position: position.normalizedPosition,
                    userId: (position?.selected[0] as any)?.id || "",
                };
            });

            try {
                const res = await ReportService.reassignSignatories({
                    id: this.report.id as string,
                    body,
                });
                this.$bvModal.hide(`modal-reassign-signatories-${this.report?.id}`);

                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
            } catch (error) {
                this.isReassigning = false;
            } finally {
                this.isReassigning = false;
            }
        },
        async save(isLater = false) {
            if (isLater) this.isSavingDraft = true;
            else this.isSaving = true;

            const body = this.signatoriesPositions.map((position) => {
                return {
                    position: position.normalizedPosition,
                    userId: (position?.selected[0] as any)?.id || "",
                };
            });

            try {
                const res = await ReportService.updateSignatories({
                    id: this.report.id as string,
                    body,
                    reviewerId: this.report?.reviewerId
                });

                this.$bvToast.toast(res.data.message, {
                    title: "Success!",
                    variant: "success",
                    solid: true,
                });
            } catch (error) {
                this.isSaving = false;
                this.isSavingDraft = false;
            } finally {
                this.isSaving = false;
                this.isSavingDraft = false;
            }
        },
        async saveDraft() {
            await this.save(true);
        },
        async fetchUserList() {
            try {
                this.isFetching = true;
                await this.fetchUsers();
            } catch (error) {
                this.isFetching = false;
            } finally {
                this.isFetching = false;
            }
        },
        goToReport() {
            this.$router.push({
                name: HOME.VIEW_REPORT.NAME,
                params: { id: this.report.id as string },
            });
        },
        getUser(userId: string) {
            return this.signatories.find(
                (signatory: any) => signatory.id === userId,
            );
        },
        matchForm(payload: any) {
            this.signatoriesPositions.forEach((position, index) => {
                this.signatoriesPositions[index].selected = [
                    this.getUser(
                        payload.signatories.find(
                            (signatory: any) =>
                                signatory.normalizedName ===
                                position.normalizedPosition,
                        )?.userId,
                    ),
                ] as any;
            });
        },
        async fetchReportById() {
            this.isFetchingPreview = true;
            try {
                const res = await ReportService.fetchReportById(this.report.id as string);
                this.reportPreview = res.data;
            } catch (error) {
                this.isFetchingPreview = false;
            } finally {
                this.isFetchingPreview = false;
            }
        },
        async fetchReportDetails() {
            this.isFetchingPreview = true;
            try {
                const res = await ReportService.fetchReportDetails(this.report.id as string);
                this.reportDetails = res.data;
                this.matchForm(res.data);
            } catch (error) {
                this.isFetchingPreview = false;
            } finally {
                this.isFetchingPreview = false;
            }
        },
        async fetchUserData () {
            const res = await UserService.fetchUsers();
            const reviewers = res.data.filter((data: any) => data.roleIds.includes("ReportReviewer"))
            this.allreviewers = reviewers
        },
    },
    mounted() {
        this.fetchUserData()
        if (this.users.length === 0) {
            this.fetchUserList();
        }
    },
    watch: {
        report(curr, prev) {
            if (curr?.id && curr !== prev) {
                this.matchForm(curr);
            }
        },
    },
    validations: {
        form: {
            managingDirectorSignatories: {},
            chairmanOfBoardSignatories: {},
            executives: {},
            investorRelationsOfficers: {},
            complianceOfficers: {},
            companyContactPersons: {}
        },
        signatoriesPositions: {
            required,
            $each: {
                selected: {
                    maxLength: maxLength(1),
                    minLength: minLength(1),
                },
            },
        },
    },
});
