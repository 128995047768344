
import Vue from "vue";
import { mapGetters, mapActions, mapState } from "vuex";
import ReportsTab from "../../components/tabs/index/ReportsTab.vue";
import ReviewDeskTab from "../../components/tabs/index/ReviewDeskTab.vue";
import SignatureDeskTab from "../../components/tabs/index/SignatureDeskTab.vue";
import NccgInstructionModal from "../../components/modals/NccgInstructionModal.vue";
import CreateReportModalOption from "../../components/modals/CreateReportModalOption.vue";
import { HOME, SETTINGS } from "../../routes/endpoints";
import ReportService from "../../../api/report-service";

enum IReportFilter {
    ALL = "ALL",
    DRAFT = "DRAFT",
    PROCESSING = "PROCESSING",
    SENT = "SENT",
    COMPLIANT = "COMPLIANT",
    NON_COMPLIANT = "NON_COMPLIANT",
    FLAGGED = "FLAGGED",
}

export default Vue.extend({
    components: {
        ReportsTab,
        ReviewDeskTab,
        SignatureDeskTab,
        NccgInstructionModal,
        CreateReportModalOption,
    },
    data() {
        return {
            isCreating: false,
            isLoading: false,
            reassignedReport: null,
            tabs: [
                {
                    text: "My Reports",
                    value: "reports",
                    component: "reports-tab",
                },
                {
                    text: "My Desk - Review",
                    value: "review-desk",
                    component: "review-desk-tab",
                },
                {
                    text: "My Desk - Signature",
                    value: "signature-desk",
                    component: "signature-desk-tab",
                },
            ],
        };
    },
    computed: {
        ...mapGetters({ profileData: "auth/getUserData" }),
        ...mapState("reports", ["reports"]),
        ...mapState("users", {
            reviewers: (state: any) => state.levels?.find((level: any) => level.category === "ReportReviewer")?.users,
            signatories: (state: any) => state.levels?.find((level: any) => level.category === "ReportSignatory")?.users,
        }),
        canCreateReport(): boolean {
            return this.profileData?.roles?.includes("Admin") || this.profileData?.roles?.includes("ReportCreator");
        },
        currentTab(): { text: string; value: string; component: string } {
            const { tab }: { tab?: string } = this.$route.query;

            const res = this.filteredTabs.find((tabItem: any) => tabItem?.value === tab);

            return res ?? this.filteredTabs[0];
        },
        filteredTabs(): any {
            const val = [];
            if (
                this.profileData?.roles?.includes("ReportReviewer") ||
                this.profileData?.roles?.includes("ReportSignatory") ||
                this.profileData?.roles?.includes("ReportCreator") ||
                this.profileData?.roles?.includes("Admin")
            ) {
                val.push({
                    text: "All Reports",
                    value: "reports",
                    component: "reports-tab",
                });
            }
            if (this.profileData?.roles?.includes("ReportReviewer")) {
                val.push({
                    text: "My Desk - Review",
                    value: "review-desk",
                    component: "review-desk-tab",
                });
            }

            if (this.profileData?.roles?.includes("ReportSignatory")) {
                val.push({
                    text: "My Desk - Signature",
                    value: "signature-desk",
                    component: "signature-desk-tab",
                });
            }

            return val;
        },
        badgeColor() {
            const { filter } = this.$route.query;
            let res = { bg: "blue-100", text: "primary" };
            if (filter === IReportFilter.DRAFT) res = { bg: "blue-100", text: "primary" };
            else if (filter === IReportFilter.SENT) res = { bg: "success-5", text: "success-90" };
            else if (filter === IReportFilter.COMPLIANT) res = { bg: "success-5", text: "success-90" };
            else if (filter === IReportFilter.PROCESSING) res = { bg: "yellow-50", text: "yellow-100" };
            else if (filter === IReportFilter.NON_COMPLIANT) res = { bg: "red-10", text: "danger" };
            else if (filter === IReportFilter.FLAGGED) res = { bg: "red-10", text: "danger" };
            return res;
        },
        buttonText(): string {
            let text = "Start";
            if (!this.isCompanyProfileComplete) {
                text = "Complete Company Profile";
            } else if (this.isCompanyProfileComplete && !this.hasAReviewer) {
                text = "Add Reviewer";
            } else if (this.isCompanyProfileComplete && this.hasAReviewer && !this.hasASignatory) {
                text = "Add Signatory";
            } else if (this.isCompanyProfileComplete && this.hasAReviewer) {
                text = "Create Report";
            }

            return text;
        },
        isPersonalProfileComplete(): boolean {
            return this.profileData.isProfileUpdated;
        },
        isCompanyProfileComplete(): boolean {
            return this.profileData.isCompanyProfileUpdated;
        },
        hasAReviewer(): boolean {
            return this.reviewers?.length > 0;
        },
        hasASignatory(): boolean {
            return this.signatories?.length > 0;
        },
        hasAReport(): boolean {
            return this.reports.length > 0;
        },
        hasDismissedInfo(): boolean {
            return !!localStorage.getItem("BA_DISMISS_INFO");
        },
    },
    methods: {
        ...mapActions("reports", ["fetchReportList"]),
        isActiveTab(tab: string): boolean {
            return this.currentTab?.value === tab;
        },
        saveDismiss() {
            localStorage.setItem("BA_DISMISS_INFO", "1");
        },
        selectTemplate() {
            this.$bvModal.hide("modal-select-regulator");
            this.$bvModal.show("modal-select-template");
        },
        openNccgInstructions() {
            this.$bvModal.hide("modal-select-template");
            this.$bvModal.show("modal-nccg-instruction");
        },
        fetchSelectedReport(data: any) {
            this.$store.commit("reports/SET_EXISTING_REPORT", data);
            this.showNccgModal();
        },
        async createReport({ confirmation, reportingFrom, reportingTo }: { confirmation: boolean; reportingFrom: string; reportingTo: string }): Promise<any> {
            const TEMPLATE_ID = "d1c395e5-c10c-4412-a601-e7f8b7273274";
            const REGULATOR_ID = "1FRC";

            try {
                if (confirmation) {
                    this.isCreating = true;
                    const res = await ReportService.createReport({
                        templateId: TEMPLATE_ID,
                        regulatorId: REGULATOR_ID,
                        reportingFrom,
                        reportingTo,
                    });

                    if (res.status === 200) {
                        this.$bvModal.hide("modal-nccg-instruction");
                        this.$router.push({
                            name: HOME.CREATE_REPORT.NAME,
                            query: {
                                id: res?.data?.creationId,
                            },
                        });
                    }
                } else {
                    this.$bvModal.hide("modal-nccg-instruction");
                }
            } catch (error) {
                this.isCreating = false;
                this.$bvToast.toast("Report Could not be instantiated", {
                    title: "Something went wrong",
                    variant: "danger",
                    solid: true,
                });
            } finally {
                this.isCreating = false;
            }
        },
        async fetchReports() {
            this.isLoading = true;
            try {
                await this.fetchReportList();
            } catch (error) {
                this.isLoading = false;
            } finally {
                this.isLoading = false;
            }
        },
        takeAction(): void {
            if (!this.isCompanyProfileComplete) {
                this.$router.push({
                    name: SETTINGS.BUSINESS.NAME,
                    query: {
                        "onboarding-step": "1",
                    },
                });
            } else if (this.isCompanyProfileComplete && !this.hasAReviewer) {
                this.$router.push({
                    name: SETTINGS.USER_ACCESS.NAME,
                    query: {
                        "onboarding-step": "2",
                        "tab": "reviewers-tab",
                    },
                });
            } else if (this.isCompanyProfileComplete && this.hasAReviewer && !this.hasASignatory) {
                this.$router.push({
                    name: SETTINGS.USER_ACCESS.NAME,
                    query: {
                        "onboarding-step": "3",
                        "tab": "signatories-tab",
                    },
                });
            } else if (this.isCompanyProfileComplete && this.hasAReviewer && this.hasASignatory) {
                this.$bvModal.show("modal-create-report-option");
            }
        },
        showNccgModal() {
            this.$bvModal.hide("modal-create-report-option");
            this.$bvModal.show("modal-nccg-instruction");
        },
        toggleOptionModals() {
            this.$bvModal.show("modal-create-report-option");
            this.$bvModal.hide("modal-nccg-instruction");
        },
    },
    mounted() {
        if (this.$route.query["activate-modal"]) {
            this.$bvModal.show("modal-nccg-instruction");
        }
        this.fetchReports();
    },
});
