
import Vue from "vue";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { HOME } from "../../../routes/endpoints";
import ReportItem from "../../reports/ReportItem.vue";

enum IReportFilter {
    ALL = "ALL",
    DRAFT = "DRAFT",
    PROCESSING = "PROCESSING",
    SENT = "SENT",
    COMPLIANT = "COMPLIANT",
    NON_COMPLIANT = "NON_COMPLIANT",
    FLAGGED = "FLAGGED",
    FINALISED = "FINALISED",
}

export default Vue.extend({
    components: {
        ReportItem,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            currentPage: 1,
            reportFilter: "ALL",
            perPage: 10,
            rows: 100,
            search: "",
            fields: [
                {
                    key: "title",
                    label: "title",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "details",
                    label: "Created By",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "current_desk_user",
                    label: "Current Desk User",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "template",
                    label: "template",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "regulator",
                    label: "regulator",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },
                {
                    key: "date",
                    label: "date",
                    tdClass: "align-middle pl-4",
                    thClass: "align-middle pl-4",
                },

                {
                    key: "entry_status",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
                {
                    key: "action",
                    label: "",
                    tdClass: "align-middle",
                    thClass: "align-middle",
                },
            ],
            items: [
                {
                    id: 1,
                    fullName: "Benjamin Samson",
                    email: "benjamin.sam@bodadmin.com",
                    title: "Compliance Status with the Nigerian Code of Corporate Governance-2022 - Awaiting Company Signatory",
                    template: "Nigerian Code of Corporate Governance (NCCG)",
                    regulator: "Financial Reporting council of Nigeria (FRC)",
                    date: "14/01/2022",
                    imageUrl: "",
                    roles: [
                        {
                            name: "Director",
                        },
                    ],
                    committees: 2,
                    entry_status: "Draft",
                },
                // {
                //     name: "Vivien Grace",
                //     email: "vivien.grace@bodadmin.com",
                //     imageUrl: "",
                //     roles: [
                //         {
                //             name: "Director",
                //         },
                //         {
                //             name: "Executive",
                //         },
                //     ],
                //     committees: 3,
                //     entry_status: "Draft",
                // },
            ],
            reportFilters: [
                {
                    label: "All",
                    key: IReportFilter.ALL,
                    tooltip: "All reports can be viewed here",
                },
                {
                    label: "Draft",
                    key: IReportFilter.DRAFT,
                    tooltip:
                        "All reports that are yet to be completed and are editable",
                },
                {
                    label: "Processing",
                    key: IReportFilter.PROCESSING,
                    tooltip:
                        "All reports that are awaiting internal review and signatures",
                },
                {
                    label: "Submitted to the FRC",
                    key: IReportFilter.SENT,
                    tooltip: "All Reports on regulator's desk",
                },
                {
                    label: "Finalised",
                    key: IReportFilter.FINALISED,
                    tooltip: "Reports are finalised by regulator standard",
                },

                {
                    label: "Flagged",
                    key: IReportFilter.FLAGGED,
                    tooltip: "Reports flagged by regulators",
                },
            ],
        };
    },
    computed: {
        ...mapState("reports", ["reports"]),
        ...mapGetters({ profileData: "auth/getUserData" }),
        mappedReports(): any[] {
            return this.reports
                .map((val: any) => {
                    const statuses = [];

                    if (val.reportStatus === 0) {
                        statuses.push(IReportFilter.DRAFT);
                    }

                    if (
                        (val.reportStatus === 7 ||
                            val.reportStatus === 3 ||
                            val.reportStatus === 8 ||
                            val.reportStatus === 12) &&
                        !val.dateSentToRegulator
                    ) {
                        statuses.push(IReportFilter.PROCESSING);
                    }

                    if (val.reportStatus === 5 || val.reportStatus === 6) {
                        statuses.push(IReportFilter.SENT);
                    }

                    if (val.reportStatus === 6) {
                        statuses.push(IReportFilter.FINALISED);
                    }

                    if (val.reportStatus === 6 && val.flagCount > 0) {
                        statuses.push(IReportFilter.FLAGGED);
                    }

                    return {
                        ...val,
                        filters: statuses,
                    };
                })
                ?.sort(
                    (a: any, b: any) =>
                        new Date(b.dateModified).getTime() -
                        new Date(a.dateModified).getTime(),
                );
        },
        itemOptions(): any[] {
            return [
                {
                    text: "View",
                    action: (item: any) => {
                        this.goTo({
                            name: HOME.VIEW_REPORT.NAME,
                            params: { id: item.id },
                        });
                    },
                },
                {
                    text: "Edit",
                    action: (item: any) => {
                        this.goTo({
                            name: HOME.CREATE_REPORT.NAME,
                            query: { id: item.id },
                        });
                    },
                },
                {
                    text: "Delete",
                    action() {
                        //
                    },
                },
            ];
        },
        filteredReports(): any {
            const reports = this.mappedReports.filter((rep) =>
                this.reportFilter === IReportFilter.ALL
                    ? rep
                    : rep.filters.includes(this.reportFilter),
            );

            if (!this.search) return reports;

            return reports?.filter(
                (report: any) =>
                    report.reportTitle
                        ?.toLowerCase()
                        ?.includes(this.search.toLowerCase()) ||
                    report.reportCode
                        ?.toLowerCase()
                        ?.includes(this.search.toLowerCase()),
            );
        },
        otherReports(): any {
            return this.filteredReports.filter((rep: any) => rep.reportStatus !== 0)
        },
        reviewerAndSignatory(): boolean {
            return (
                this.profileData?.roles?.includes("ReportReviewer") ||
                this.profileData?.roles?.includes("ReportSignatory")
            );
        },
        userBasedReport(): any {
            return this.reviewerAndSignatory ? this.otherReports : this.filteredReports
        }
    },
    methods: {
        ...mapActions("reports", ["fetchReportList"]),
        ...mapMutations("reports", ["removeReport"]),
        goTo({
            name,
            params,
            query,
        }: {
            name?: string;
            params?: any;
            query?: any;
        }) {
            this.$router.push({ name, params, query });
        },
        handleFilterChange(val: any) {
            this.$router.replace({
                query: { ...this.$route.query, filter: val as string },
            });
        },
        async handleRemoveReport(id: string) {
            this.removeReport(id);
            await this.fetchReportList();
        },
    },

    mounted() {
        const { filter } = this.$route.query;
        if (filter) {
            this.reportFilter = filter as string;
        } else {
            this.reportFilter = "ALL";
        }
        this.handleFilterChange(this.reportFilter);
    },
});
