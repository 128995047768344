var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('ba-base-dialog', {
    staticClass: "selectable-modal selectable-modal_container",
    attrs: {
      "id": `reassign-signatories-${_vm.report.id}`,
      "title": "Reassign Signatories",
      "description": `Provide different signatories for this report`,
      "custom-content-class": "overflow-scroll selectable-modal__content"
    }
  }, [_vm.isLoading ? _c('div', {
    staticClass: "text-center"
  }, [_c('ba-loader')], 1) : _c('section', [_c('b-row', {
    staticClass: "mx-0"
  }, [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-row', _vm._l(_vm.signatoriesPositions, function (signatoriesPosition, ix) {
    return _c('b-col', {
      key: ix,
      attrs: {
        "cols": "12"
      }
    }, [_c('ba-selectable-file-input', {
      attrs: {
        "label": signatoriesPosition.position,
        "placeholder": "Search to select Signatories",
        "selected-label": 'Selected Signatories',
        "listTitle": "Signatories",
        "create-label": 'Create New User',
        "values": _vm.signatories,
        "loadingValues": _vm.isFetching,
        "type": "single",
        "required": signatoriesPosition.isRequired,
        "defaultValue": _vm.signatoriesPositions[ix].selected,
        "is-value-editable": ""
      },
      on: {
        "create": function ($event) {
          return _vm.$bvModal.show('modal-create-user');
        },
        "blur": function ($event) {
          return _vm.$v.signatoriesPositions.$touch();
        },
        "editValue": function ($event) {
          return _vm.handleUserEditValue({
            modalId: 'modal-create-user',
            data: $event,
            ref: 'createUserModal'
          });
        }
      },
      scopedSlots: _vm._u([{
        key: "error",
        fn: function () {
          return [_vm.$v.signatoriesPositions.$error ? _c('div', {
            staticClass: "error"
          }, [_vm._v(" Select at least one signatory ")]) : _vm._e()];
        },
        proxy: true
      }], null, true),
      model: {
        value: _vm.signatoriesPositions[ix].selected,
        callback: function ($$v) {
          _vm.$set(_vm.signatoriesPositions[ix], "selected", $$v);
        },
        expression: "signatoriesPositions[ix].selected"
      }
    })], 1);
  }), 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('create-user-modal', {
    ref: "createUserModal",
    attrs: {
      "modalId": "create-user"
    },
    on: {
      "completed": function ($event) {
        _vm.fetchUserList();
        _vm.$bvModal.hide('modal-create-user');
      }
    }
  })], 1)], 1), _c('b-row', {
    staticClass: "mt-5"
  }, [_c('b-col', {
    staticClass: "d-flex flex-row justify-content-between align-items-center",
    attrs: {
      "cols": "12"
    }
  }, [_c('ba-button', {
    staticClass: "text-black",
    attrs: {
      "text": "Cancel",
      "variant": "light"
    },
    on: {
      "click": function ($event) {
        return _vm.$bvModal.hide(`modal-reassign-signatories-${_vm.report.id}`);
      }
    }
  }), _c('ba-button', {
    staticClass: "border-0 bg-yellow-70 text-gray-dark",
    attrs: {
      "disabled": _vm.$v.$invalid || _vm.isReassigning,
      "loading": _vm.isReassigning,
      "text": "Reassign"
    },
    on: {
      "click": _vm.reassignSignatories
    }
  })], 1)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }